.shai-primary-menu-items {
    list-style-type: none;
    cursor: pointer;

}

#shai-header-primary-menu>li {
    -webkit-border-radius: 1.5rem;
    border-radius: 1.5rem;

}

#shai-header-primary-menu>li:hover {
    background-color: #e1f3f8;
    color: #162e51;
    border: 2px solid red;

}

.secondary-menu-drop-down-items {
    margin-left: -1rem;
    margin-bottom: 0;
    margin-top: 1.5rem;
    list-style-type: none;
    padding-left: 0;
    background-color: #162e51;
    color: white;
    width: 20rem;
    position: absolute;
    z-index: 400;
    border: 2px solid yellow;

}


.secondary-mega-menu {
    left: 10%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    margin-top: 1.5rem;
    position: fixed;
    width: 80%;
    height: 60%;
    z-index: 400;
    border: 1px solid #0050d8;
    border-top: 4px solid #0050d8;
}

.secondary-mega-menu-card-container {
    min-height: 80%;
}

.secondary-mega-menu-card {
    flex: 1;
    z-index: 500;
    margin: 8px;
    padding: 8px;
    border-right: 2px solid #0050d8;
}