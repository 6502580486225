.shai-main-page-hero-section {
    flex: 1;
}

.shai-ds-u-border-color--primary {
    border: 2px solid #005ea2;
}

.shai-main-page-hero-section-highlight {
    color: yellow;
}


.shai-main-page-2nd-section {
    margin: 2rem;
    padding: 2rem;
    flex: 1;
    border: 2px solid #0050d8;
    background-color: white;
}

.shain-main-page-card-section {
    flex: 1;
    height: 18rem;

}

.shai-ds-u-card {
    flex: 1;

}


.shai-ds-u-card__heading {
    flex: 1;
}

.shai-ds-u-card__content {
    flex: 4
}



.shai-ds-u-svg {
    height: 3rem;
    width: 3rem;

}

.shai-ds-u-footer-section1 {
    flex: 1;
}

.shai-ds-u-footer-section2 {
    flex: 1;
}

.sh-application-section {
    height: 16rem;
}

.sh-application-footer {
    height: 12rem;
}

.shai-flex-two-parts-size {
    flex: 2
}

.shai-flex-two-third {
    flex: 3
}

.shai-flex-one-third {
    flex: 3
}