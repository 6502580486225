.shai-dataplatform-image {
    width: 100%;
    height: auto;
}


@media (min-width: 768px) {
    .shai-dataplatform-image {
        width: 100%;
        height: auto;
    }
}

.shai-menu-items {
    list-style-type: none;
}

.shai-horizontal-rule {
    border-bottom: 4px solid red;
}

.shai-logo-name-cursor {
    cursor: pointer;
}

.shai-datapipeline-image {
    width: 100%;
    height: auto;
}


@media (min-width: 768px) {
    .shai-datapipeline-image {
        width: 100%;
        height: auto;
    }
}

.shai-flex-two-parts-size {
    flex: 1
}