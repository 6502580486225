.shai-publichealth-image {
    width: 100%;
    height: auto;
}


@media (min-width: 768px) {
    .shai-publichealth-image {
        width: 100%;
        height: 100%;
    }
}

.shai-flex-two-parts-size {
    flex: 1
}

.shai-flex-two-third {
    flex: 3
}

.shai-flex-one-third {
    flex: 1
}