.shai-logo-image {
    width: 30px;
    height: 23px;
}


@media (min-width: 768px) {
    .shai-logo-image {
        width: 59px;
        height: 46px;
    }
}

.shai-menu-items {
    list-style-type: none;
}

.shai-horizontal-rule {
    border-bottom: 4px solid red;
}

.shai-logo-name-cursor {
    cursor: pointer;
}